<template>
  <change-password-form :token="token" />
</template>

<script>
import ChangePasswordForm from "./../components/ChangePasswordForm";
export default {
  components: {
    ChangePasswordForm
  },
  computed: {
    token() {
      return this.$route.params.token;
    }
  }
};
</script>

<style scoped></style>
